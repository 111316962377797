* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 13px;
}

body::-webkit-scrollbar-thumb {
  background: #5B5980 !important;
  border-radius: 10px !important;
}

body::-webkit-scrollbar-track {
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px grey; 
}


/* FONTS FONTS FONTS FONTS FONTS FONTS FONTS FONTS */

@font-face {
  font-family: 'Nunito Black';
  src: url('../assets/fonts/Nunito-Black.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Bold';
  src: url('../assets/fonts/Nunito-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Bold Italic';
  src: url('../assets/fonts/Nunito-BoldItalic.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Extra Bold';
  src: url('../assets/fonts/Nunito-ExtraBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Extra Bold Italic';
  src: url('../assets/fonts/Nunito-ExtraBoldItalic.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Extra Light';
  src: url('../assets/fonts/Nunito-ExtraLight.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Extra Light Italic';
  src: url('../assets/fonts/Nunito-ExtraLightItalic.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Italic';
  src: url('../assets/fonts/Nunito-Italic.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Light';
  src: url('../assets/fonts/Nunito-Light.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Light Italic';
  src: url('../assets/fonts/Nunito-LightItalic.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Regular';
  src: url('../assets/fonts/Nunito-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Semi Bold';
  src: url('../assets/fonts/Nunito-SemiBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito Semi Bold Italic';
  src: url('../assets/fonts/Nunito-SemiBoldItalic.ttf') format('truetype')
}

@font-face {
  font-family: 'LuckiestGuy Regular';
  src: url('../assets/fonts/LuckiestGuy-Regular.ttf') format('truetype')
}

/* NAVBAR NAVBAR NAVBAR NAVBAR NAVBAR NAVBAR NAVBAR NAVBAR */

.navbar-bg {
  background-color: #D58830;
}

#hamburguer-btn {
  border: 0;
  color: #D58830;
  padding: 0;
  padding-left: 1%;
}

.hamburguer-icon {
  font-size: 50px;
  color: #FFF;
}

.medium-logo {
  height: 60px;
}

@media (max-width: 990px) {
  .medium-logo, .cart-logo {
    display: none;
  }
  .cart-logo-2 {
    height: 40px;
  }
  #item-nav {
    font-size: 3vw !important;
  }
}

.small-logo {
  height: 40px;
}

@media (min-width: 991px) {
  .small-logo-container, 
    .cart-logo-2-container {
    display: none;
  }
}

#item-nav {
  color: #FFF;
  font-weight: bold;
  font-size: 1.5vw;
  padding-left: 3.6vw;
  padding-right: 3.6vw;
  font-family: Nunito Black;
}

.cart-logo {
  width: 90%;
}

.scrolled-down{
  transform: translateY(-100%); transition: all 0.3s ease-in-out;
}

.scrolled-up{
  transform: translateY(0); transition: all 0.3s ease-in-out;
}

.navbar-sticky {
  position: sticky;
  top: 0;
  z-index: 1001;
}

/* CART CART CART CART CART CART CART CART CART CART CART */

.cart-background {
  position: absolute;
  height: 100%;
  width: 120px;
  right: 100%;
}

.offcanvas {
  background-color: #5B5980;
  border: 0;
}

.offcanvas-end {
  width: 40%;
}

.offcanvas-header {
  align-items: baseline;
}

#offcanvasRightLabel {
  color: #FEFEFE;
  font-size: 2vw;
  padding-top: 0%;
  font-family: Nunito Black;
}

.btn-close {
  background: none;
  color: #FEFEFE !important;
  font-size: 2vw;
  opacity: 1;
  padding: 0;
  margin: 0;
  font-family: Nunito Black;
}

.close {
  color: #FEFEFE !important;
}

.cart-divider {
  width: 85%;
  height: 5px;
  background-color: #D59431;
  border-radius: 10px;
  margin: 0 auto;
}

.image-cart {
  width: 140%;
}

.cart-description {
  color: #FEFEFE;
  margin: auto 0;
  padding-right: 0;
  margin-left: 4%;
}

.remove-section {
  width: 8%;
}

.remove-item {
  color: #FEFEFE;
  font-size: 2vw;
  font-family: Nunito Black;
  background-color: transparent;
  border: 0;
}

.cart-description p {
  color: #FEFEFE;
  font-size: 2vw;
  font-family: Nunito Black;
  text-align: center;
  margin-bottom: 0;
}

.item-description {
  font-size: 1vw !important;
  font-family: Nunito Bold !important;
}

.choose-1-cart, .flavor-cart {
  font-size: 0.9vw !important;
}

.cart-description section {
  margin: 0 auto;
}

.cart-description input {
  background-color: rgba(255, 255, 255, 0.4);
  border: 0;
  padding: 1% 5%;
  border-radius: 10px;
  font-family: Nunito Bold;
  font-size: 1.3vw;
}

.cart-description input::-webkit-outer-spin-button,
.cart-description input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-description input[type=number] {
  -moz-appearance: textfield;
}


.cart-description input:active {
  border: 0;
}

.cart-description button {
  border: 0;
  background-color: transparent;
  color: #FEFEFE;
  font-family: Nunito Black;
  font-size: 2vw;
  padding: 3%;
}

.cart-input-amount, .cart-input-price {
  color: #FEFEFE;
  text-align: center;
}

.cart-input-amount {
  width: 25%;
}

.cart-input-price {
  width: 46%;
}

.offcanvas-body {
  padding-bottom: 0;
  padding-right: 1%;
}

.cart-products {
  padding: 0;
  margin: 0;
  display: block;
  overflow: auto !important;
  width: 100%;
  height: 100%;
}

.cart-products::-webkit-scrollbar {
  width: 5px;
}

.cart-products::-webkit-scrollbar-thumb {
  background: #D59431 !important;
  border-radius: 10px !important;
}

.cart-products::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

.sticky {
  position: sticky;
  bottom: 0 !important;
  padding-top: 1%;
  padding-bottom: 5%;
}

.subtotal-cart-container {
  justify-content: flex-end;
  display: flex;
}

.checkout-cart {
  justify-content: center;
  display: flex;
}

.subtotal-cart {
  padding-right: 10%;
  color: #FEFEFE;
  font-size: 1.5vw;
  font-family: Nunito Bold;
}

.checkout-button {
  text-align: center;
  display: block;
  background-color: #E8BD4D;
  color: #FEFEFE;
  padding: 2%;
  width: 80%;
  border-radius: 10px;
  font-size: 2vw;
  font-family: Nunito Black;
  text-decoration: None;
}

.checkout-button:hover {
  color: #eeeeee;

}

.checkout-disabled {
  opacity: 0.7;
  pointer-events: none;
}

@media (max-width: 990px) {
  .offcanvas-end {
    width: 50%;
  }
  .offcanvas-header {
    padding: 0 3%;
  }
  #offcanvasRightLabel {
    font-size: 4.5vw;
    padding-top: 0%;
  }
  .btn-close {
    font-size: 6.5vw;
  }
  .cart-input-amount {
    width: 30%;
  }
  .cart-input-price {
    width: 55%;
  }
  .cart-description {
    padding: 0;
  }
  .cart-description p {
    font-size: 3vw;
  }
  .item-description {
    font-size: 2.2vw !important;
  }
  .cart-description section {
    display: flex;
    align-items: center;
  }
  .cart-description input {
    padding: 3% 5%;
    border-radius: 15px;
    font-size: 2.3vw;
  }
  .cart-description button {
    font-size: 5vw;
  }
  .remove-item {
    font-size: 4.5vw;
  }
  .subtotal-cart {
    padding-right: 10%;
    font-size: 2.7vw;
  }
  .checkout-button {
    border-radius: 10px;
    font-size: 4vw;
  }
}

/* HOME HOME HOME HOME HOME HOME HOME HOME HOME HOME HOME */

.home-header {
  width: 100%;
  filter: brightness(75%);
}

.background-1 {
  position: absolute;
  width: 100%;
  margin-top: -14vw;
}

.space-1 {
  margin-top: 6%;
}

.background-3 {
  position: absolute;
  width: 100%;
  margin-top: -17.4%;
}

#carousel-header {
  z-index: -1;
}

.home-logo {
  position: relative;
  text-align: center;
  width: 35%;
  margin-top: -27%;
  animation: slide-in-fwd-center 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.home-dot {
  position: relative;
  margin-top: -12%;
  width: 13%;
  z-index: 1;
}

#home-dot-1 {
  margin-bottom: -10%;
  animation: dot-1 1.1s both;
}

#home-dot-2 {
  margin-bottom: -19%;
  animation: dot-1 1.3s both;
}

#home-dot-3 {
  margin-left: 51%;
  margin-bottom: -19%;
  animation: dot-1 1.5s both;
}

@keyframes dot-1 {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

.hover-container {
  display: none;
}

.background-hover {
  position: absolute;
  margin-top: -57.44%;
  width: 100%;
  opacity: 0.6;
  z-index: 0;
}

.carousel-container:hover + .hover-container {
  display: flex;
  pointer-events: none;
  flex-direction: column;
}

#hover-1, #hover-2, #hover-3 {
  position: relative;
  display: none;
}

.hover-active {
  display: block !important;
}

.hover-title {
  z-index: 2;
  color: #5B5980;
  font-size: 5vw;
  margin-top: -22%;
  padding-left: 15vw;
  padding-right: 15vw;
  text-align: center;
  font-family: LuckiestGuy Regular;
}

.hover-text {
  z-index: 0;
  color: #5B5980;
  font-size: 2vw;
  margin-top: -24%;
  padding-left: 15vw;
  padding-right: 15vw;
  text-align: center;
  font-family: Nunito Black;
}

.carousel-container {
  margin-top: 6%;
  height: 100%;
}

.carousel-img {
  width: 70%;
}

.arrow {
  height: 10%;
  margin-top: 50%;
}

.shop-now {
  z-index: 3;
  color: #FFF;
  font-size: 5vw;
  margin-top: -6%;
  font-family: Nunito Black;
  text-decoration: none;
}

.shop-now:hover {
  color: #ebebeb;
}

.shop-now-shadow {
  z-index: 1;
  width: 30%;
}

#row-correction {
  margin-right: 0;
  margin-left: 0;
}

#icons-container, #icons-container-2 {
  padding-top: 10vw;
}

.icon-img {
  padding-top: 10%;
  width: 40%;
}

.icon-img:hover {
  transform: scale(1.1);
}

.wisconsin-img {
  width: 13%;
  height: 100%;
}

.wisconsin-img:hover {
  transform: scale(1.1);
}


.icon-text {
  color: #FFF;
  text-align: center;
  width: 40%;
  font-size: 90%;
  margin-top: 3%;
  font-family: Nunito Black;
}

.icon-img-an {
  animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.icon-img-an-2 {
  animation: slide-in-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.wisconsin-img-2 {
  width: 25vw;
}

@media (max-width: 990px) {
  #icons-container {
    display: none !important;
  }
}

@media (min-width: 991px) {
  #icons-container-2 {
    display: none !important;
  }
}

.icon-img-2 {
  height: 12vw;
}

.icon-img-2:hover {
  transform: scale(1.1);
}

.wisconsin-img-2:hover {
  transform: scale(1.1);
}

.icon-text-2 {
  color: #FFF;
  text-align: center;
  width: 10vw;
  font-size: 2vw;
}

.margin-top {
  padding-top: 4vw;
}

.gluten {
  padding-right: 20vw;
}

.pieces {
  padding-left: 20vw;
  
}

.simple {
  padding-top: 5vw;
  padding-left: 8vw;
}

.vegetarian {
  padding-top: 5vw;
  padding-right: 8vw;
}

.flickity-img {
  width: 25%;
  margin-left: 1%;
  margin-right: 1%;
}

.flickity-img:hover {
  transform: scale(0.99);
}

.flickity-container {
  overflow: hidden;
  padding-top: 4vw;
}

.text-1, .text-2, .text-3 {
  font-size: 1.6vw;
  width: 10%;
  text-align: center;
  color: #FFF;
  margin-top: 2vw;
  padding-left: 3%;
  padding-right: 3%;
  font-family: Nunito Black;
}

.home-background-2 {
  background-color: #484870;
  height: auto;
  margin-top: 3%;
}

@media (max-width: 990px) {
  .flickity-img {
    width: 45%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .carousel-text {
    display: inline;
  }
  .text-1, .text-2, .text-3 {
    display: grid;
    flex: auto;
    width: 40%;
    font-size: 2.7vw;
    margin: auto;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .text-3 {
    margin-bottom: 1%;
  }
  .text-4 {
    display: none;
  }
}

@media (min-width: 581px) 
and (max-width: 680px) {
  .background-1 {
    margin-top: -14.9vw;
  }
  .background-hover {
    margin-top: -58.3vw;
  }
  .home-dot {
    margin-top: -16%;
  }
  .background-3 {
    margin-top: -18.2%;
  }
}

@media (min-width: 381px) 
and (max-width: 580px) {
  .background-1 {
    margin-top: -16.7vw;
  }
  .background-hover {
    margin-top: -60.1vw;
  }
  .home-dot {
    margin-top: -20%;
  }
  .background-3 {
    margin-top: -20.2%;
  }
}

@media (max-width: 380px) {
  .background-1 {
    margin-top: -19.5vw;
  }
  .background-hover {
    margin-top: -62.9vw;
  }
  .home-dot {
    margin-top: -22%;
  }
  .background-3 {
    margin-top: -22.7%;
  }
}

.home-end {
  width: 100%;
  margin-top: -1%;
}

/* FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */

.home-background-3 {
  height: 500px;
  padding-bottom: 30%;
}

.footer-text {
  color: #D89731;
  padding-left: 10%;
  padding-right: 14%;
  font-size: 1.5vw;
  /* text-align: justify; */
  font-family: Nunito Bold;
}

.footer-text p {
  margin-bottom: 0;
}

.footer-text address p {
  padding-bottom: 15% !important;
}

.see-terms {
  font-size: 1.5vw;
  color: #D89731;
  padding-bottom: 10%;
}

.see-terms:hover {
  color: #D89731;
}

.see-terms-2 {
  font-size: 1.5vw;
  color: #FEFEFE;
  padding-bottom: 2%;
}

.see-terms-2:hover {
  color: #D89731;
}

.icon {
  padding-right: 5vw;
  font-size: 3vw;
  color: #D89731;
}

.footer-form {
  padding-left: 10%;
  padding-right: 10%;
}

.form-logo-orange {
  height: 4vw;
  margin-bottom: 5%;
}

.form-input {
  background-color: #D89731;
  border-radius: 15px;
  border: 0;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  margin-top: 1% !important;
  color: #FFF;
  font-size: 1vw;
  font-family: Nunito Bold;
}

.form-input::placeholder {
  color: #FFF;
}

.form-textarea {
  height: 150px;
}

.form-submit {
  color: #FFF;
  background-color: #D89731;
  border: 0;
  border-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1vw;
  font-family: Nunito Bold;
}

@media (max-width: 990px) {
  .footer-text {
    font-size: 3.7vw;
    padding-left: 14%;
    padding-right: 14%;
    padding-bottom: 10%;
    padding-top: 15%;
  }
  .footer-form {
    padding-left: 14%;
    padding-right: 14%;
    font-size: 3vw;
  }
  .form-logo-orange {
    height: 9vw !important;
  }
  .icon {
    font-size: 5vw;
  }
  .form-input, .form-submit {
    font-size: 2.5vw !important;
    padding-bottom: 0px;
  }
  .see-terms, .see-terms-2 {
    font-size: 3.5vw;
  }
}

/* OUR FAMILY OUR FAMILY OUR FAMILY OUR FAMILY OUR FAMILY */

.background1 {
  position: absolute;
  height: 34vw;
  width: 100%;
  background-image: url("./img/family-background-1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.family-logo {
  position: relative;
  text-align: center;
  width: 35%;
  margin-top: 7%;
  animation: slide-in-fwd-center 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.family-dot {
  position: absolute;
  margin-top: 19%;
  width: 13%;
  z-index: 1;
}

#family-dot-1 {
  margin-left: 5%;
  margin-bottom: -10%;
  animation: dot-1 1.1s both;
  /* pointer-events: none; */
}

#family-dot-2 {
  margin-left: 20%;
  margin-bottom: -19%;
  animation: dot-1 1.3s both;
}

#family-dot-3 {
  margin-left: 75%;
  margin-bottom: -19%;
  animation: dot-1 1.5s both;
}

.background2 {
  position: absolute;
  width: 100%;
  margin-top: 18%;
}

.family-2 {
  margin-top: 40%;
}

.family-group1 {
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 15%;
}

.icon-person {
  height: 100%;
  width: 5vw;
  margin-left: 5%;
}

.icon-bread {
  height: 100%;
  width: 8vw;
  margin-left: 2%;
}

.icon-house {
  height: 100%;
  width: 6vw;
}

.icon-person:hover, 
.icon-bread:hover,
.icon-house:hover {
  animation: shake 0.6s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.about-family, .how-started, .proud {
  width: 70%;
}

.how-started {
  margin-left: 30%;
}

.proud {
  margin-right: 5%;
  width: 61% !important;
}

.family-title {
  color: #D89731;
  font-size: 3.2vw;
  white-space: nowrap;
  font-family: LuckiestGuy Regular;
}

.paragraph {
  font-size: 1.3vw;
  color: #5B5980;
  font-family: Nunito Bold;
}

#our-family-video {
  width: 100%;
}

.video-msg {
  float: right;
  margin-top: -9%;
  font-family: Nunito Bold;
  font-size: 1.3vw;
  color: #FEFEFE;
}

.background3, .background4 {
  background-color: #5D8494;
}

.background3 {
  margin-top: -1%;
  padding: 10% 0;
}

.america {
  width: 30%;
  margin-left: 5%;
  animation: heartbeat 2s ease-in-out infinite both;
}

@keyframes americaHeartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  5% {
    transform: scale(0.93);
    animation-timing-function: ease-in;
  }
  13% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  19% {
    transform: scale(0.9);
    animation-timing-function: ease-in;
  }
  23% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  25% {
    transform: scale(0);
    animation-timing-function: ease-out;
  }
}


.giving-back {
  padding-left: 4%;
  padding-right: 8%;
  color: #FFF;
  font-family: LuckiestGuy Regular;
}

.giving-back h2 {
  font-size: 4vw;
}

.giving-back p {
  font-size: 1.4vw;
  font-family: Nunito Bold;
}

.family-carousel {
  overflow: hidden;
  padding-bottom: 10%;
  margin-top: -1px;
}

.family-carousel img {
  width: 365px;
  height: 365px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 25px;
  object-fit: cover;
}

.family-carousel img:hover {
  transform: scale(0.99);
}

.background5 {
  width: 100%;
  margin-top: -1px;
}

@media (max-width: 990px) {
  .about-family, .how-started, .proud {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    margin-top: 6%;
    margin-bottom: 3%;
  }
  .family-title {
    font-size: 6vw;
  }
  .video-msg {
    font-size: 2vw;
  }
  .paragraph {
    font-size: 3vw;
    margin-top: 3%;
  }
  .family-group1 {
    padding-right: 10%;
  }
  .icon-person {
    width: 10vw;
  }
  .icon-bread {
    width: 15vw;
  }
  .icon-house {
    width: 13vw;
  }
  .giving-back {
    padding-top: 5%;
  }
  .giving-back p {
    font-size: 2.5vw;
  }
  .america {
    width: 70%;
    padding-top: 9%;
    margin-left: 5%;
  }
  .family-carousel img {
    width: 200px;
    height: 200px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 574px) {
  .icon-person {
    width: 13vw;
  }
  .icon-bread {
    width: 19vw;
  }
  .icon-house {
    width: 17vw;
  }
}

/* PRODUCTS PRODUCTS PRODUCTS PRODUCTS PRODUCTS PRODUCTS PRODUCTS */

.products-dot {
  position: absolute;
  margin-top: -2%;
  width: 13%;
  z-index: 1;
}

#products-dot-1 {
  margin-left: 5%;
  margin-bottom: -10%;
  animation: dot-1 1.1s both;
}

#products-dot-2 {
  margin-left: 18%;
  margin-bottom: -19%;
  animation: dot-1 1.3s both;
}

#products-dot-3 {
  margin-left: 79%;
  margin-bottom: -19%;
  animation: dot-1 1.5s both;
}

.products-back1 {
  width: 100%;
  margin-top: -3%;
  filter: brightness(100%);
}

.product-img {
  width: 75%;
}

.product-img-2 {
  width: 65%;
}

.product-text {
  margin: auto 0;
}

.product-text h5 {
  color: #D59431;
  font-family: LuckiestGuy Regular;
  font-size: 3.2vw;
}

.product-text p {
  color: #484870;
  font-family: Nunito Bold;
  padding: 0% 10% 0% 0%;
  font-size: 1.3vw;
}

.product-text button {
  background-color: #484870;
  border: 0;
  border-radius: 25px;
  padding: 1% 3%;
  font-size: 1.2vw;
  color: #FEFEFE;
  font-family: Nunito Bold;
}

.product-text button:hover {
  color: #E8BD4D;
}

.product-back {
  width: 100%;
}

.product-back-2 {
  width: 100%;
  margin-top: -0.3%;
  margin-bottom: 5%;
}

.green {
  background-color: #849E31;
  color: #FEFEFE;
  font-size: 1.3vw;
  padding: 5% 5%;
  margin-top: -0.3%;
}

.green h4 {
  font-family: LuckiestGuy Regular;
  font-size: 4.5vw;
}

.green h5 {
  font-family: Nunito Black;
  font-size: 1.5vw;
  padding: 0 12%;
}

.green img {
  width: 8vw;
  margin: 7% 0;
}

.oven {
  padding-top: 9%;
}

.oven:hover {
  animation: heartbeat 1.2s ease-in-out infinite both;
}

.air-fryer:hover {
  animation: wobble-hor-bottom 1s infinite both;
}

.important {
  padding-top: 13%;
}

.important:hover {
  animation: shake-horizontal 1.1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.bullet {
  margin: 20px 0;
}

.bullet::marker {
  color: #E8BD4D;
}

.green-padding {
  padding-top: 6%;
}

@media (max-width: 990px) {
  .product-list {
    margin: 5% 0;
  }
  .product-img, .product-img-2 {
    width: 100%;
    padding-left: 10%;
  }
  .product-text h5 {
    font-size: 5vw;
    padding-left: 0%;
  }
  .product-text p {
    font-size: 2vw;
    padding-left: 0%;
    margin-bottom: 2%;
  }
  .product-text button {
    font-size: 2vw;
    margin-left: 0%;
  }
  .green {
    font-size: 3vw;
  }
  .green h4 {
    font-size: 5vw;
    margin-top: 5%;
  }
  .green h5 {
    font-size: 3vw;
  }
  .green img {
    width: 15vw;
  }
  .instructions {
    padding: 0 15% !important;
  }
  .instructions:last-child {
    margin-bottom: 8%;
  }
  .oven {
    padding-top: 0%;
  }
  .air-fryer, .important {
    padding-top: 10%;
  }
}

/* PRODUCT PRODUCT PRODUCT PRODUCT PRODUCT PRODUCT PRODUCT */

.product-dot {
  position: absolute;
  margin-top: -9%;
  width: 13%;
  z-index: 1;
}

#product-dot-1 {
  margin-top: -5.5%;
  margin-left: 13%;
  margin-bottom: -10%;
  animation: dot-1 1.1s both;
}

#product-dot-2 {
  margin-left: 85%;
  margin-bottom: -19%;
  animation: dot-1 1.5s both;
}

.products-header {
  width: 100%;
  margin-top: -5.8%;
  filter: brightness(100%);
}

.products-body {
  background-color: #5D8595;
  margin-top: -1%;
}

.products {
  padding: 0;
  margin: auto 0;
}

.products img {
  width: 70%;
}

.carousel-indicators {
  margin-bottom: 0;
}

#carousel-indicator {
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50%;
  margin-top: 20%;
}

.information {
  color: #FEFEFE;
  padding: 0 5% 0 0;
}

.information h5 {
  font-size: 3.4vw;
  font-family: Nunito Black;
  color: #e6981b;
}

.description {
  font-size: 1.6vw;
  font-family: Nunito Bold;
}

.description p {
  margin-bottom: 4%;
}

#price {
  padding-top: 3%;
  font-size: 3vw;
  font-family: Nunito Black;
  margin-bottom: 0;
  color: #e6981b;
}

#ingredients, #allergen {
  font-weight: bold;
  font-size: 2vw;
}

#ingredients button, #allergen button {
  font-size: 2vw;
  background-color: #5D8595;
  border: 0;
  color: #FEFEFE;
  margin-top: 3%;
  font-family: Nunito Bold;
  color: #FEFEFE;
}

#ingredients button:hover, #allergen button:hover {
  color: #D89731;
}

#ingredients button:hover ~ .dropdown-menu,
#allergen button:hover ~ .dropdown-menu {
  display: block;
}

.flavor {
  background-color: #EBBD81;
  color: #5D8595;
  padding: 1% !important;
  white-space: nowrap;
  border-radius: 10px;
  text-align: center;
  font-size: 1.7vw;
  margin: 2% 3%;
  font-family: Nunito Black;
}

.flavor:hover {
  color: #FEFEFE;
}

.ingredients-2, .ingredients-3,
.flavor-2:hover ~ .ingredients-1,
.flavor-3:hover ~ .ingredients-1  {
  display: none;
}

.ingredients-1,
.flavor-1:hover ~ .ingredients-1, 
.flavor-2:hover ~ .ingredients-2,
.flavor-3:hover ~ .ingredients-3 {
  display: block;
}

.dropdown-text {
  background-color: #EBBD81 !important;
  font-size: 1.3vw;
  color: #5D8595;
  padding: 25px;
  border-radius: 15px;
  font-family: Nunito Bold;
}

#ingredients .dropdown-menu, #allergen .dropdown-menu {
  background-color: transparent;
  border: 0;
}

.bread-1 {
  position: absolute;
  top: -29%;
  right: 20vw;
  width: 25%;
}

.drops {
  margin-top: 5%;
}

.btn-radio {
  background-color: #FEFEFE;
  color: #5D8595;
  padding: 7px 0;
  font-size: 2.7vw;
  white-space: nowrap;
  border-radius: 10px;
  border: 0;
  font-family: LuckiestGuy Regular;
  width: 80%;
}

.checked {
  color: #D59431 !important;
}

#buy-once-radio {
  display: none;
}

#buy-once-radio:checked ~ #buy-once-label {
  color: #D59431;
}

.club-group .dropdown-menu {
  background-color: #5D8595;
  border: 0;
  border-radius: 10px;
  font-family: LuckiestGuy Regular;
  color: #FEFEFE;
  padding: 0 2.5% 0 0;
}

.radio-text {
  font-size: 2.5vw;
  text-align: left;
}

.form-check {
  padding-left: 2.5vw;
}

#radio-button1, #radio-button2, 
#radio-button3, #radio-button4,
#radio-button5, #radio-button6 {
  background-image: none;
  margin-top: 1.3ch;
  border: 0;
  vertical-align: middle;
  width: 1.2vw;
  height: 1.2vw;
  margin-left: -2.5vw;
}

#radio-button1:checked, #radio-button2:checked,
#radio-button3:checked, #radio-button4:checked,
#radio-button5:checked, #radio-button6:checked {
  background-color: #D59431;
  border-color: #D59431;
}

.join-club {
  margin-top: 2%;
}

.ship {
  font-size: 1.5vw;
  color: #FEFEFE;
  padding-left: 14%;
  font-family: LuckiestGuy Regular;
}

.address {
  margin: 6.5% 12.5% 0 17%;
}

.btn-add {
  background-color: #D59431;
  color: #FEFEFE;
  padding: 10px 20px;
  font-size: 2.3vw;
  border-radius: 10px;
  border: 0;
  margin-bottom: 5%;
  font-family: LuckiestGuy Regular;
}

.icon-2 {
  padding-left: 5vw;
  font-size: 3.5vw;
  color: #FEFEFE;
}

.products-body address {
  color: #FEFEFE;
  font-size: 2vw;
  font-weight: bold;
  font-family: Nunito Bold;
}

.products-body address p {
  font-size: 1.7vw;
  margin-bottom: 0;
}

.products-body address span {
  font-family: Nunito Black;
}

.products-header-2 {
  width: 100%;
  margin-top: -1%;
}

@media (max-width: 990px) {
  .products img {
    width: 75%;
  }
  #carousel-indicator {
    width: 3vw;
    height: 3vw;
  }
  .information {
    padding: 0 12.5%;
  }
  .information h5 {
    font-size: 7vw;
    margin-top: 10%;
  }
  .description {
    font-size: 3vw;
  }
  #price {
    font-size: 6vw;
  }
  .flavor {
    font-size: 3.2vw;
  }
  .dropdown-text {
    font-size: 3vw;
  }
  #ingredients button, #allergen button {
    font-size: 4vw;
  }
  .col-hidden {
    display: none;
  }
  .club-group {
    padding: 6% 0% 10% 15%;
  }
  #buy-once {
    padding-bottom: 1% !important;
  }
  .join-club {
    margin-top: 0;
  }
  .btn-radio {
    width: 50%;
    font-size: 5vw;
  }
  #radio-button1, #radio-button2, 
  #radio-button3, #radio-button4,
  #radio-button5, #radio-button6 {
    margin-top: 1.7ch;
    width: 1.8vw;
    height: 1.8vw;
  }
  .radio-text {
    font-size: 5vw;
  }
  .ship {
    font-size: 2.5vw;
    padding-left: 7%;
  }
  .btn-add {
    font-size: 5vw;
  }
  .address {
    margin: 6% 14% 0 14%;
  }
  .address p {
    font-size: 3vw !important;
    margin: 5% 0 !important;
  }
  .address span {
    font-size: 5vw;
  }
  .icon-2 {
    font-size: 6vw;
  }
}

/* BITTES&FRUIT BITTES&FRUIT BITTES&FRUIT BITTES&FRUIT BITTES&FRUIT */

.smoothies img {
  width: 120%;
  margin-top: 5%;
}

.smoothies span {
  color: #FEFEFE;
  font-family: Nunito Black;
  font-size: 1.48vw;
}

.smoothie-name-1, .smoothie-name-3 {
  margin-left: 20%;
}

.smoothie-name-2 {
  margin-left: 5%;
}

.smoothie-name-0 {
  margin-left: 35%;
}

.ingredients-strawberry,
.passion-strawberry,
.mango-strawberry,
.blackberry-strawberry {
  display: none;
}

.ingredients-strawberry,
.passion-strawberry,
.mango-strawberry,
.blackberry-strawberry {
  position: absolute;
  background-color: #EBBD81;
  border-radius: 15px;
  font-size: 1.2vw;
  padding: 15px 25px;
  color: #5D8595;
  font-family: Nunito Bold;
}

.smoothie:hover ~ .ingredients-strawberry,
.smoothie:hover ~ .passion-strawberry,
.smoothie:hover ~ .mango-strawberry,
.smoothie:hover ~ .blackberry-strawberry {
  display: block;
}

.smoothie:hover {
  transform: scale(1.1);
}

.bundle-up {
  text-align: center;
}

.radio-bundle {
  font-size: 2.36vw;
  text-align: left;
}

.smoothie-col {
  padding: 0;
  margin: 0;
}

.choose-input section {
  margin: auto 0;
}

.choose-input section button {
  background-color: #5D8595;
  border: 0;
  color: #FEFEFE;
  font-family: Nunito Black;
  font-size: 2.2vw;
  padding: 3%;
}

.choose-input section input {
  background-color: rgba(255, 255, 255, 0.4);
  border: 0;
  text-align: center !important;
  padding: 0% 0%;
  margin: 0 5%;
  border-radius: 70%;
  font-family: Nunito Bold;
  font-size: 2vw;
  width: 38%;
  color: #FEFEFE;
}

.choose-input .col-10 {
  width: 77%;
  padding-right: 0 !important;
}

.choose-input .col-2 {
  width: 23%;
  padding: 0 0;
}

.btn-bundle {
  background-color: #FEFEFE;
  color: #5D8595;
  padding: 7px 0;
  width: 80%;
  font-size: 2.7vw;
  border-radius: 10px;
  border: 0;
  font-family: LuckiestGuy Regular;
}

.drop-group .dropdown-menu {
  background-color: #5D8595;
  border: 0;
  border-radius: 10px;
  font-family: LuckiestGuy Regular;
  color: #FEFEFE;
  position: relative;
}

.address-fruit {
  margin: -5% 12.5% 0 17%;
}

.address-fruit {
  color: #FEFEFE;
  font-size: 2vw;
  font-weight: bold;
  font-family: Nunito Bold;
}

.address-fruit .soon {
  opacity: 0.6;
}

.address-fruit p {
  font-size: 1.7vw;
  margin-bottom: 0;
}

.address-fruit span {
  font-family: Nunito Black;
}

.choose {
  margin-top: 2%;
}

.buy {
  margin-top: 2%;
}

@media (max-width: 990px) {
  .drops {
    margin-top: 15%;
  }
  .smoothies img {
    width: 77%;
    margin-top: 5%;
  }
  .smoothies span {
    font-size: 3.5vw;
  }
  .smoothie-name-1, 
  .smoothie-name-3, .smoothie-name-2 {
    margin-left: 0%;
  }
  .smoothie-name-0 {
    margin-left: 11%;
  }
  .smoothies .text-center {
    margin-top: 10%;
  }
  .ingredients-strawberry,
  .passion-strawberry,
  .mango-strawberry,
  .blackberry-strawberry {
    font-size: 2vw;
  }
  .btn-bundle {
    padding: 7px 0;
    font-size: 4vw;
  }
  .buy {
    margin-top: 10%;
  }
  .choose-1, .choose-3, 
  .buy-once, .join-the-club {
    text-align: center !important;
  }
  .choose-1 .dropdown-menu, 
  .choose-3 .dropdown-menu,
  .join-the-club .dropdown-menu {
    margin-left: 10%;
  }
  .choose-3 {
    margin-top: 4%;
  }
  .radio-bundle {
    font-size: 5vw;
  }
  .join-the-club {
    margin-top: 3%;
  }
  .address-fruit {
    margin: 0% 12.5% 0 17%;
  }
  .address-fruit p {
    font-size: 3vw !important;
    margin: 5% 0 !important;
  }
  .address-fruit span {
    font-size: 5vw;
  }
  .choose-input section button {
    font-size: 4vw;
    padding: 3%;
  }
  .choose-input section input {
    padding: 0% 0%;
    margin: 0 5%;
    border-radius: 50%;
    font-size: 3.5vw;
    width: 30%;
  }
}

@media (min-width: 991px) {
  .choose-1-col, .choose-3-col,
  .once-col, .join-col {
    display: none;
  }
}

@media (max-width: 575px) {
  .smoothies span {
    font-size: 8vw;
  }
}

/* CONTACT CONTACT CONTACT CONTACT CONTACT CONTACT CONTACT CONTACT */

.contact-dot {
  position: absolute;
  margin-top: -7%;
  width: 13%;
  z-index: 1;
}

#contact-dot-1 {
  margin-top: -5%;
  margin-left: 13%;
  margin-bottom: -10%;
  animation: dot-1 1.1s both;
}

#contact-dot-2 {
  margin-left: 85%;
  margin-bottom: -19%;
  animation: dot-1 1.5s both;
}

.contact-header {
  width: 100%;
  margin-top: -6.5%;
  filter: brightness(100%);
}

.contact-background {
  background-color: #D58830;
  height: 500px;
  padding-bottom: 30%;
  padding-top: 5%;
}

.contact-text {
  background-color: #D58830;
  color: #FEFEFE;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 1.5vw;
  font-family: Nunito Bold;
}

.contact-text p {
  margin-bottom: 0;
}

.contact-text address p {
  padding-bottom: 15% !important;
}

.contact-icon {
  padding-right: 5vw;
  font-size: 3vw;
  color: #FEFEFE;
}

.form-logo-orange {
  height: 4vw;
  margin-bottom: 5%;
}

.contact-form {
  background-color: #D58830;
  padding-left: 10%;
  padding-right: 10%;
}

.contact-form-input {
  background-color: #FEFEFE;
  border-radius: 15px;
  border: 0;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  margin-top: 1% !important;
  color: #D58830;
  font-size: 1vw;
  font-family: Nunito Bold;
}

.contact-form-input::placeholder {
  color: #D58830;
}

.contact-form-submit {
  color: #D58830;
  background-color: #FEFEFE;
  border: 0;
  border-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1vw;
  font-family: Nunito Bold;
}

@media (max-width: 990px) {
  .contact-text {
    font-size: 3.7vw;
    padding-left: 14%;
    padding-right: 14%;
    padding-bottom: 10%;
    padding-top: 15%;
  }
  .contact-form {
    padding-top: 7%;
    padding-left: 14%;
    padding-right: 14%;
    font-size: 3vw;
  }
  .contact-form-input, 
  .contact-form-submit {
    font-size: 2.5vw !important;
    padding-bottom: 0px;
  }
  .contact-icon {
    font-size: 5vw;
  }
}

/* CHECKOUT CHECKOUT CHECKOUT CHECKOUT CHECKOUT */

.my-cart {
  font-size: 2vw;
  margin-left: 10%;
  font-family: Nunito Bold;
}

.cart-item {
  display: flex;
  align-items: center;
  text-align: center;
}

.payment {
  padding-top: 5%;
  padding-left: 4%;
  padding-right: 1%;
}

#submit-button {
  display: none;
}

.cart {
  padding-top: 5%;
  padding-left: 1%;
  padding-right: 4%;
}

.shipping {
  padding-left: 0;
  padding-right: 1%;
}

.billing {
  padding-left: 1%;
  padding-right: 0;
}

.payment span {
  font-size: 1.5vw;
  margin-left: 5%;
  font-family: Nunito Bold;
}

.contact-info {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5%;
  margin: 5% 0;
}

.contact-info input {
  display: block;
  width: 100%;
  padding: 1% 3%;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-size: 1vw;
}

.state {
  padding: 0;
  padding-right: 3%;
}

.zip-code {
  padding: 0;
}

.same-as {
  margin-left: 1%;
  margin-bottom: 5%;
  font-size: 1vw;
}

.proceed {
  background-color: #FFC439;
  border: 0;
  border-radius: 25px;
  padding: 2%;
  font-size: 1.3vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: Nunito Bold;
}

.proceed .spinner-border {
  margin-right: 10px;
  width: 23px;
  height: 23px;
}

.square {
  padding: 5% 0;
}

.square-pay {
  font-size: 1.7vw;
  border: 0;
  border-radius: 30px;
  padding: 1% 0;
  background-color: #FFC439;
  display: block;
  margin: 0 auto;
  font-family: Nunito Bold;
  width: 30%;
  color: #414545;
  text-align: center;
}

.square-pay img {
  width: 45%;
  margin-bottom: 1%;
}

.or {
  display: block;
  margin: 0 !important;
  text-align: center;
  padding-bottom: 5%;
  font-size: 2vw !important;
}

#paypal-button {
  text-align: center;
  margin-bottom: 8%;
}

.cart-item img {
  width: 140%;
  /* text-align: center !important; */
}

.cart-item span {
  font-size: 2vw;
  font-family: Nunito Black;
  color: #414545;
}

.cart-item p {
  color: #414545;
  margin: 1%;
  font-size: 1.2vw;
  font-family: Nunito Bold;
}

.receipt {
  margin-top: 20%;
  margin-bottom: 20%;
  font-family: Nunito Bold;
}

.receipt div {
  display: flex;
  justify-content: space-between;
  margin: 2%;
  font-size: 1.4vw;
}

.receipt .input-group {
  width: 96%;
}

@media (max-width: 990px) {
  .my-cart {
    font-size: 5vw;
  }
  .payment {
    padding-left: 5%;
    padding-right: 5%;
  }
  .cart-item img {
    width: 80%;
  }
  .cart-item span {
    font-size: 7vw;
    font-family: Nunito Black;
  }
  .cart-item p {
    margin: 1%;
    font-size: 4vw;
  }
  .square-pay {
    width: 40%;
  }
  .square-pay span {
    font-size: 3vw !important;
  }
  .square-pay img {
    width: 40%;
    margin-bottom: 2%;
  }
  .or {
    font-size: 3.5vw !important;
  }
  .receipt {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
  }
  .receipt div {
    font-size: 3vw;
  }
  .shipping {
    padding-right: 0;
  }
  .billing {
    padding-left: 0;
  }
  .payment span {
    font-size: 4vw;
  }
  .contact-info {
    padding: 2%;
  }
  .contact-info input {
    padding: 1% 3%;
    font-size: 3vw;
  }
  .state input {
    padding-left: 5%;
  }
  .same-as {
    margin-left: 4%;
    /* margin-bottom: 5%; */
    font-size: 2.5vw;
  }
}

/* MODAL MODAL MODAL MODAL MODAL MODAL MODAL */

.modal-container {
  display: none;
  position: fixed;
  z-index: 10000; 
  padding-top: 10%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0;
  border-radius: 15px;
  width: 40%;
}

.modal-content-info {
  background-color: #fefefe;
  margin: auto;
  border: 0;
  border-radius: 15px;
  width: 80%;
}

.modal-header {
  padding: 2% 3%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-body p {
  margin-bottom: 0;
}

.modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 1.5vw;
}

.modal-close:hover,
.modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN */

.login-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Nunito Black;
}

.admin-logo {
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
}

/* ADMIN PRODUCTS ADMIN PRODUCTS ADMIN PRODUCTS */

.admin-product {
  margin: 5%;
}

.admin-product label {
  margin-top: 2%;
}

.loading {
  text-align: center;
  display: block;
  margin-top: 15%;
  font-size: 2.5vw;
  font-family: Nunito Black;
}

.loading .spinner-border {
  font-size: 1.5vw;
}

.image-container {
  position: relative;
}

.image-container button {
  position: absolute;
  top: 0;
  /* right: 10%; */
  /* background-color: Transparent; */
  border: 0;
  padding: 3px 8px;
  border-radius: 15px;
}

.image-container button i {
  font-size: 1.2vw;
}

.download-image {
  right: 55%;
}
.edit-image {
  right: 40%;
}
.view-image {
  right: 25%;
}
.remove-image {
  right: 10%;
}

.admin-images {
  text-align: center;
}

.admin-product-image {
  width: 100%;
}

.bi-plus-circle-dotted::before {
  font-size: 4vw;
  background-color: #E9ECEF;
  padding: 40%;
  border-radius: 20px;
}

.admin-smoothies img {
  width: 100%;
}

.remove-contact {
  background-color: transparent;
  border: 0;
}

.square-payments,
.square-customers, 
.square-invoices,
.square-catalogs,
.paypal-plans,
.paypal-orders {
  margin: 3%;
}

.settings {
  margin: 5% 14%;
}

.shipping-values .col-3 {
  width: 20%;
}

.coupons-icons {
  font-size: 120%;
  margin-left: 4%;
  margin-right: 4%;
}

.add-icon {
  font-size: 237%;
}

.spin {
  width: 18px;
  height: 18px;
}

.bi-eye, .bi-eye-slash {
  font-size: 1.5vw;
  padding-left: 1%;
  padding-top: 0.4%;
}

.admin-terms {
  padding-bottom: 5%;
}

.msg-404 {
  color: #FEFEFE;
  font-family: Nunito Black;
  font-size: 2.4vw;
  margin-top: 5%;
  text-align: center;
}

.bigger {
  transform: scale(1.1);
}
